<template>
  <div>
    <download-chart
      v-if="download"
      noDownload
      feature="Water"
      @click="downloadChartImage()"
    ></download-chart>

    <div class="chart-container">
      <canvas ref="chart" width="100" id="chart"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import ChartDataLabels from 'chartjs-plugin-datalabels';
import utils from '@/mixins/utils';
export default {
  mixins: [utils],
  props: {
    download: {
      type: Boolean,
      default: false
    },
    value: {},
    yTitle: {
      type: String,
      default: 'Nitrate mg/L'
    }
  },
  components: {
    DownloadChart: () => import('@/components/Core/DownloadChart.vue')
  },
  data: () => ({
    chartData: {},
    context: null,
    chartObj: null
  }),
  mounted() {
    this.chartData = {
      type: 'bar',
      plugins: [ChartDataLabels],
      options: {
        onClick: this.handleClick,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.yTitle,
              font: {
                size: '20px'
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            font: {
              weight: 'bold'
            }
          }
        }
      },
      data: {
        labels: [],
        datasets: [
          {
            label: 'Nitrate mg/L',
            data: [],
            backgroundColor: [],
            // borderColor: "rgb(255, 99, 132)",
            maxBarThickness: 90,
            borderWidth: 1
          }
        ]
      }
    };

    this.setContext();
    this.initChart();
    this.generateData();
  },
  methods: {
    setContext() {
      this.context = document.getElementById('chart').getContext('2d');
    },
    initChart() {
      this.chartObj = new Chart(this.context, this.chartData);
    },
    generateData() {
      this.chartObj.destroy();
      this.chartData.data.datasets[0].data = [];
      this.chartData.data.labels = [];
      this.chartData.data.datasets[0].backgroundColor = [];
      this.value.forEach(data => {
        this.chartData.data.labels.push(data.testDate);
        this.chartData.data.datasets[0].data.push(data.nitrate);
        this.chartData.data.datasets[0].backgroundColor.push(this.getNitrateColor(data.nitrate));
      });
      this.initChart();
    },
    showChart() {
      this.chartObj.update();
    },
    handleClick(_e, item) {
      let val = null;
      if (item.length) {
        val = this.value[item[0].index];
      }
      this.$emit('clickBar', val);
    },
    downloadChartImage(filename) {
      this.downloadBase64Image(this.$refs.chart.toDataURL('image/png'), filename);
    }
  },
  watch: {
    value() {
      this.generateData();
      this.showChart();
    }
  }
};
</script>

<style scoped>
.chart-container {
  /* min-height: 353px; */
  height: 55vh;
}
</style>
